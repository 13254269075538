.sb-configurator {
    width: 100%;
    height: 100%;
}

.sb-configurator__inner-container {
    display: flex;
    height: 100%;
}

.sb-configurator__viewer {
    flex-basis: 66.66%;
}

.sb-configurator__editor {
    flex-basis: 33.33%;
    padding: 24px;
    box-shadow: rgba(0, 0, 0, 0.3) 3px 3px 9px 9px;
}

.sb-viewer__screenshot-btn {
    position: fixed;
    z-index: 2;
    top: 24px;
    left: 24px;
}

.sb-product-model__decal-tooltip .sb-decal-tooltip__inner-container {
    border-radius: 100%;
    cursor: pointer;
    padding: 15px;
    background: rgba(0, 0, 0, 0.6)
}